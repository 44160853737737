<!-- Start Page Title Area -->
<div class="page-title-area item-bg-business jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Conta Empresarial</h2>
            <p>Tudo que você precisa em um só lugar</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Currency Area -->
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Sua conta corporativa</h3>
                        <p>Controle os pagamentos e recebimentos da sua empresa em um só lugar</p>
                        <p>Com as nossas soluções corporativas, você se preocupa somente com o que interessa, a sua empresa, os seus clientes, os seus colaboradores, nós cuidamos de todos o resto pra você</p>
                        <ul class="list">
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Receba Pagamentos por Boleto, PIX, Cartão e outros
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Custos Reduzidos
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Conta Protegida
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Receba em mais de 140 países
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <a href="https://app.taminobank.com.br/auth/registration" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->

    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Como Funciona?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Abra sua conta</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Abra sua conta gratuitamente, basta clicar no link, preencher os dados e enviar a sua documentação</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Receba pagamentos</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Comece a receber seus pagamentos na sua conta Tamino, seja ele PIX, Boleto, TED, cartões e outros.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Integre os seus Sistemas</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Com as nossas APIs você integra os seus sistemas internos e facilita a sua vida</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->



    <!-- Start Protec Area -->
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>No que vamos te ajudar?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Receba Pagamentos</h3>
                            <p>Receba seus pagamentos por transferência, TED, PIX, Boleto, link de pagamento, cartão de crédito e muito mais</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Faça seus Pagamentos</h3>
                            <p>Pague boletos, contas de consumo, impostos, envio PIX, TED e transferências, faça o sua folha de pagamentos</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Gerenciamento de riscos</h3>
                            <p>Nossos sistemas de gerenciamento de riscos minimizam a inadimplencia, charge backs ou outros problemas que você possa ter</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Venda OnLine</h3>
                            <p>Utilize nossos serviços de checkout ou programa de afiliados para vender on line seja você um expert ou um iniciante, nossas soluções são pra você</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->



    <app-talk-button></app-talk-button>

</div>