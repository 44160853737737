import { Component } from '@angular/core';

@Component({
  selector: 'app-baas',
  templateUrl: './baas.component.html',
  styleUrls: ['./baas.component.scss']
})
export class BaasComponent {

}
