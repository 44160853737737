<!-- Start Page Title Area -->
<div class="page-title-area item-bg-escrow  jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Conta Escrow</h2>
            <p>Abra sua conta Escrow com a Tamino</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Currency Area -->
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image-escrow"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Sua conta Escrow</h3>
                        <p>Sua conta aberta em até 24 horas pelos nossos especialistas</p>
                        <p>Abra sua Conta Escrow na Tamino em até 1 dias, diminua os riscos em transações e gerencie contas de múltiplos clientes com poucos cliques</p>
                        <ul class="list">
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Régua de cobrança automatica na titularidade do cliente
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Permissões Flexiveis, uma conta, diversas funções
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Conta Protegida
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Pagamentos por boleto, transferência ou PIX
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <a href="https://app.taminobank.com.br/auth/registration" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->

    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Como Funciona?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Abra sua conta</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Abra sua conta gratuitamente, basta clicar no link, preencher os dados e enviar a sua documentação</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Receba pagamentos</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Comece a receber seus pagamentos na sua conta Tamino, seja ele PIX, Boleto, TED, cartões e outros.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Integre os seus Sistemas</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Com as nossas APIs você integra os seus sistemas internos e facilita a sua vida</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->



    <!-- Start Protec Area -->
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>No que vamos te ajudar?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Receba Pagamentos</h3>
                            <p>Receba seus pagamentos por transferência, TED, PIX, Boleto, link de pagamento, cartão de crédito e muito mais</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Faça seus Pagamentos</h3>
                            <p>Pague boletos, contas de consumo, impostos, envio PIX, TED e transferências, faça o sua folha de pagamentos</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Gerenciamento de riscos</h3>
                            <p>Nossos sistemas de gerenciamento de riscos minimizam a inadimplencia, charge backs ou outros problemas que você possa ter</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Venda OnLine</h3>
                            <p>Utilize nossos serviços de checkout ou programa de afiliados para vender on line seja você um expert ou um iniciante, nossas soluções são pra você</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->



    <!-- Start FAQ Area -->
    <div class="ctp-faq-area ptb-100">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Perguntas frequentes:</h2>
            </div>
            <div class="ctp-faq-accordion">
                <div class="accordion" id="FaqAccordion">
                    <div class="accordion-item">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            O que é Conta Escrow e como ela funciona na prática?
                        </button>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Uma conta escrow pode ser muito importante para as negociações da sua empresa, entenda!!
                                <p>As negociações são muito importantes para o sucesso das empresas. Negociar com fornecedores e clientes é fundamental para conseguir produtos de qualidade com o melhor custo-benefício. No entanto, assim como muitas outras, essas operações também envolvem riscos.</p>
                                <p>Para reduzir esses riscos, as empresas costumam pedir alguma garantia na negociação. Uma dessas formas, é através do escrow, mas o que é isso e qual sua importância?</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            O que é o escrow?
                        </button>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Toda empresa precisa ter gestão de riscos para ter sucesso, principalmente em uma negociação.</p>
                                <p>O escrow é fundamental para essas operações, pois esse contrato, assinado entre ambas as partes da negociação, funciona como uma garantia comercial de que o que foi acordado será cumprido. Para que isso aconteça, todas as cláusulas devem ser observadas.</p>
                                <p>Além disso, esse acordo comercial fica sob a responsabilidade de um terceiro, até que o acordo seja cumprido por ambas as partes. Com isso, essa garantia vem normalmente acompanhada de um valor em dinheiro em uma conta especificamente para esse caso, conhecida também como conta escrow, mas como ela funciona?</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Como funciona uma conta escrow?
                        </button>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Como citado anteriormente, a conta escrow, também conhecida como conta caução ou conta de garantia, serve para guardar uma quantidade em dinheiro até que ambas as partes de uma negociação cumpram com o que foi acordado.</p>
                                <p>Sendo assim, esse serviço de gerenciamento conta com um objetivo determinado entre duas partes, que pode ser utilizado em diversas operações, seja em negociações de contrato, títulos, entre outros, pode diminuir bastante o risco dessas operações.</p>
                                <p>Para que isso aconteça, o primeiro passo é os negociantes determinarem o valor que será depositado na conta escrow e as regras para a liberação do dinheiro. Após, é preciso decidir qual será o terceiro, ou seja, a parte neutra que ficará em posse do contrato até que tudo que tenha sido acordado seja cumprido, como um banco, por exemplo.</p>
                                <p>Dependendo do acordo, é possível que o valor permaneça indisponível durante anos. Contudo, há jurisprudência para isso. Assim, os ganhos provenientes da conta escrow só podem ser contabilizados quando ambas as partes cumprirem o que foi acordado e os valores forem liberados.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Quais são os tipos de negociação que esse tipo de serviço pode ser aplicado?
                        </button>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Como citado anteriormente, o escrow é um serviço fundamental para a gestão de risco de uma empresa, podendo ser utilizado nas suas negociações. Na maioria dos casos, esse serviço é aplicado em aquisições de outras empresas ou mesmo fusões. No entanto, também há outros casos que ele é empregado.</p>
                                <p>Para entender um pouco mais da importância desse serviço e de uma conta escrow, imagine que você tem uma empresa em um segmento e quer comprar outra para expandir ainda mais o seu mercado.</p>
                                <p>Antes de fechar esse negócio, é preciso realizar uma auditoria na empresa que deseja adquirir, procurando por processos judiciais e passivos, pois isso acaba protegendo o comprador de despesas extras, que aconteçam antes do fechamento da aquisição.</p>
                                <p>Para estabelecer essa proteção, podem criar uma cláusula contratual de escrow, onde parte do pagamento fica retida até a finalização de todo o processo de compra.</p>
                                <p>No entanto, além disso, é possível utilizar uma conta escrow em compras menores também, como de produtos na internet. O Mercado Pago pode ser um exemplo. Quando uma pessoa realiza uma compra no Mercado Livre, o valor fica retido até que se tenha a confirmação da entrega da mercadoria pela transportadora ou correios.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End FAQ Area -->

    <app-talk-button></app-talk-button>

</div>