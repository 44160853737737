<!-- Start Page Title Area -->
<div class="page-title-area item-bg-personal jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Conta Pessoal</h2>
            <p>Tudo o que você precisa</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Services Area -->
    <div class="ctp-services-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Conta Pessoal Tamino, tudo que você precisa</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="assets/img/currency-transfer-provider/services/mobile-payment.svg" alt="image">
                            </div>
                            Faça Pagamentos
                        </h3>
                        <p>Envie PIX, TED, pague boletos e contas de consumo, controle suas finanças e emita cobranças com toda a facilidade que só a tamino tem.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="assets/img/currency-transfer-provider/services/warning.svg" alt="image">
                            </div>
                            Receba Alertas
                        </h3>
                        <p>Enviamos mensagens pra você a cada transação na sua conta, totalmente configuravel por você, aumento sua segurança no dia a dia .</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="assets/img/currency-transfer-provider/services/fluctuation.svg" alt="image">
                            </div>
                            Relatórios Gerenciais
                        </h3>
                        <p>Controle melhor as suas finanças com nossos relatórios gerenciais, tenha total controle do que você paga e do que você recebe</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="ctp-services-card">
                        <h3>
                            <div class="icon">
                                <img src="assets/img/currency-transfer-provider/services/contract.svg" alt="image">
                            </div>
                            Cobre Recebimentos
                        </h3>
                        <p>Você profissional liberal ou autonomo, cobre o seu cliente com toda a comodidade e facilidade que só a Tamino tem</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Services Area -->

    <!-- Start Currency Area -->
    <div class="ctp-currency-area pb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Conta Pessoal Tamino</h3>
                        <p>Tenha total controle de sua conta bancária, receba e envie dinheiro, utlize o PIX ou pague suas contas com nossa solução bancária</p>
                        <p>Alem de toda a facilidade você pode contar com nosso Cartão Tamino para pagar suas compras no dia a dia</p>
                        <ul class="list">
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Envie Pagamentos
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Receba Pagamentos
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Cartão de Crédito
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Link de Pagamento
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->


    <!-- Start Protec Area -->
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Venha para a Tamino</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Abra sua conta</h3>
                            <p>Em alguns minutos preencha o seu cadastro e envie sua documentação para analise, sua conta será aberta em até 24 horas.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Receba Pagamentos</h3>
                            <p>Receba seus pagamentos na sua conta protegida Tamino, e conte com toda a facilidade de nossos serviços</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Registre sua Chave PIX</h3>
                            <p>Registre sua chave PIX e comece a receber suas transações na conta protegida da Tamino</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Solicite seu Cartão</h3>
                            <p>Solicite seu cartão Tamino para aproveitar ainda mais todas as funcões da sua conta e fazer compras no dia a dia</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->

    <!-- Start World Area -->
    <div class="ctp-world-area pb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-world-content">
                        <h3>Venda conosco para mais de 140 países</h3>
                        <div class="world-inner-card">
                            <h4>Receba na sua Conta Tamino</h4>
                            <p>Com nossas soluções de checkout você vende para mais de 140 países e recebe na sua conta Tamino</p>
                        </div>
                        <div class="world-inner-card">
                            <h4>Compre e Venda Criptomoedas</h4>
                            <p>Aproveite nossa mesa de OTC e nosso Brokers para comprar e vender suas criptomoedas sem sair da sua conta Tamino</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-world-image"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About Area -->


    <app-talk-button></app-talk-button>

</div>