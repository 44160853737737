<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Sobre nós</h2>
            <p>A história da Tamino</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start About Area -->
    <div class="ctp-about-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-image"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-content">
                        <span>Sobre a Tamino</span>
                        <h3>Ajudamos a transformar os serviços financeiros</h3>
                        <p>Serviços e produtos para facilitar a sua vida, com serviços financeiros sem complicações ajudamos você no seu dia a dia e em como você lida com seus serviços bancários</p>
                        <p>Entendemos que nossos clientes merecem os melhores serviços e produtos para crescer, faturar mais e se preocupar menos com os serviços bancários do dia a dia, desde a gestão da cobrança até recebiveis, pagamentos de contas, recebimentos, crédito e muito mais</p>
                        <h4>Nossa Missão</h4>
                        <p>Transformar o mercado de serviços financeiros trazendo para nossos clientes os melhores produtos do mercado, centralizamos em uma única plataforma de forma facilitada e intuítiva provendo serviços de qualidade e preços justos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About Area -->

    <!-- Start Fun Facts Area -->
    <div class="ctp-funfacts-area">
        <div class="container">
            <div class="ctp-funfacts-inner-box pt-100 pb-75">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="180">00</span>mil</h3>
                            <p>PIX processados por dia</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="20">00</span>bi</h3>
                            <p>Movimantados mensalmente</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="5000">00</span>+</h3>
                            <p>Transações por Segundo</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="70">00</span>mil+</h3>
                            <p>Contas abertas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Fun Facts Area -->

    <!-- Start Choose Area -->
    <div class="ctp-choose-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-choose-content without-padding">
                        <h3>Porque escolher a Tamino?</h3>
                        <p>Escolher a Tamino para seus serviços financeiros é a melhor solução para o seu dia a dia.</p>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                               Tarifas Transparentes
                            </h4>
                            <p>Você tem acesso a todas as tarifas de forma transparente, pode escolher o plano que melhor se encaixa no seu fluxo de caixa, pagamanto tarifas justas por serviços de qualidade </p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                                Tecnologia de Ponta
                            </h4>
                            <p>A Tecnlogia embarcada em nossa solução é baseada nas melhores práticas de mercado, visando sua segurança e proporcionando um melhor uso dos serviços bancários seja pelos nossos aplicativos, API ou outras soluções personalizadas</p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                                Fácil de Usar
                            </h4>
                            <p>Facilidade para você utilizar todos os serviços disponíveis e uma equipe de suporte sempre disponível para sanar suas dúvidas onde você estiver, esse é o nosso diferencial</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-choose-image with-border-radius"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Choose Area -->



    <!-- Start App Area -->
    <div class="ctp-app-area">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-content">
                        <span class="sub">Baixe nosso APP</span>
                        <h3>Baixe agora mesmo o APP Tamino</h3>
                        <p>Todo o poder do seu HUB de soluções financeira na palma da sua mão, a qualquer momento</p>
                        <div class="btn-box">
                            <a href="#" class="app-store-btn" target="_blank">
                                <i class="flaticon-apple"></i>
                                Baixar na
                                <span>App Store</span>
                            </a>
                            <a href="#" class="play-store-btn" target="_blank">
                                <i class="flaticon-play-store"></i>
                                Baixar no
                                <span>Google play</span>
                            </a>
                        </div>
                        <div class="info">
                            <span>A Tamino na palma da sua mão</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-image">
                        <img src="assets/img/currency-transfer-provider/app/app.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End App Area -->
    <app-account-button></app-account-button>
    <!-- Start Investors Area -->

    <!-- End Investors Area -->

</div>