<!-- Start Page Title Area -->
<div class="page-title-area item-bg-checkout jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Checkout Tamino</h2>
            <p>Venda em mais de 140 Países</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Produtores, Afiliados, Vendedores, Influenciadores</h2>
                    <div class="bar"></div>
                    <p>Temos a solução completa para o seu negócio, seja ele qual for. Se você vende pela internet nós somos seu melhor parceiro!</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Processar Pagamentos </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Comissões & Afiliados</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparente</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Gestão de Risco </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Anti-fraude integrado</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mais de 140 países</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/prod_afi_ven.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/ferramentas.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Ferramentas para você vender mais</h2>
                    <div class="bar"></div>
                    <p>Ferramentas que vão auxiliar no dia a dia do seu negócio e aumentar o seu faturamento.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Recuperação de Boletos</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Disparo de Emails</span></li>
                        <li><span><i class="flaticon-check-mark"></i> ChatBOT</span></li>
                        <li><span><i class="flaticon-check-mark"></i> WhatsApp API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Site Grátis</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Integrações</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Checkouts</h2>
                    <div class="bar"></div>
                    <p>Transparente, API, botão de pagamento, envio de cobrança, o que você precisa hoje?</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Checkout Personalizavel</span></li>
                        <li><span><i class="flaticon-check-mark"></i> API para integração </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Checkout Transparente</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Multi-linguas</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pagamentos Locais</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Suporte Premiun</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/checkouts.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->


<app-account-button></app-account-button>