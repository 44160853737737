<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div
            class="container"
            [ngClass]="{'container-fluid': router.url === '/demo-7'}"
        >
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logoBranco.png" alt="logo" width="210px">
                    <img src="assets/img/logo1024.png" alt="logo" width="210px">
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active" class="nav-link">Início</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">Sobre</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/baas" routerLinkActive="active" class="nav-link">BAAS</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Produtos <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/baas" routerLinkActive="active" class="nav-link">Bank as a Service</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/business-account" routerLinkActive="active" class="nav-link">Conta Empresarial</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/personal-account" routerLinkActive="active" class="nav-link">Conta Pessoal</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/escrow-account" routerLinkActive="active" class="nav-link">Conta Escrow</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/checkout" routerLinkActive="active" class="nav-link">Checkout</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/tamino-pay" routerLinkActive="active" class="nav-link">Maquina Tamino</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/otc" routerLinkActive="active" class="nav-link">OTC</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/broker" routerLinkActive="active" class="nav-link">Broker</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/cards" routerLinkActive="active" class="nav-link">Cartão Tamino</a>
                                </li>
<!--                                <li class="nav-item">-->
<!--                                    <a routerLink="/payroll" routerLinkActive="active" class="nav-link">Payroll</a>-->
<!--                                </li>-->
<!--                                <li class="nav-item">-->
<!--                                    <a routerLink="/hub" routerLinkActive="active" class="nav-link">HUB de Pagamentos</a>-->
<!--                                </li>-->
<!--                                <li class="nav-item">-->
<!--                                    <a routerLink="/cashback" routerLinkActive="active" class="nav-link">Cashback</a>-->
<!--                                </li>-->
<!--                                <li class="nav-item">-->
<!--                                    <a routerLink="/multi" routerLinkActive="active" class="nav-link">Multi-Beneficios</a>-->
<!--                                </li>-->
<!--                                <li class="nav-item">-->
<!--                                    <a routerLink="/credit" routerLinkActive="active" class="nav-link">Crédito</a>-->
<!--                                </li>-->

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contato</a>
                        </li>
                    </ul>
                    <div class="others-options">
                        <a href="https://app.taminobank.com.br"  class="login-btn"><i class="flaticon-user"></i> Entrar</a> &nbsp; &nbsp;
                        <a href="https://app.taminobank.com.br/auth/registration"  routerLinkActive="active" class="login-btn"><i class="flaticon-user"></i> Abrir Conta</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->