<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <h3>Diversas soluçoes do mercado para você e sua empresa</h3>

        <div class="partner-inner">
            <div class="row align-items-center">
                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-1.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover1.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-2.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover2.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-3.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover3.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-4.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover4.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-5.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover5.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-6.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover6.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-7.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover7.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-8.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover8.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-9.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover9.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-10.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover10.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-11.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover11.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-12.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover12.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-13.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover13.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-14.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover14.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-15.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover15.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-16.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover16.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-17.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover17.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partner-image/partner-18.png" alt="partner">
                        <img src="assets/img/partner-image/partner-hover18.png" alt="partner">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->