<!-- Start Page Title Area -->
<div class="page-title-area item-bg-baas jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Um Banco só seu</h2>
            <p>Como funciona o BaaS da Tamino</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Já pensou em ter um banco com o seu nome?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Integre</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Através das APIs da Tamino você transforma sua empresa em uma prestadora de serviços financeiros, com soluções sob medida, time técnico especializado disponível 24 horas por dia, licença bancária e muito mais.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Fature</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Aumente o faturamento da sua empresa cobrando tarifas pelos serviços financeiros prestados e ganhando comissões sobre produtos de crédito e investimentos.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Lance o seu Banco</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Você pode desenvolver o seu proprio banco ou utlizar o nosso White Label e ja comecar a abrir contas em menos de 20 dias.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->

    <!-- Start Protec Area -->
    <div class="ctp-protec-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>O que você vai ter com a solução BAAS Tamino</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Aplicativo Web e Mobile</h3>
                            <p>Aplicativo Web e Mobile com a sua marca e a nossa tecnologia para que você possa começar em aproximadamente 20 dias úteis</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>API</h3>
                            <p>Acesso a nossas APIs para que você possa desenvolver a sua própria solução de banco.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>03</span>
                            </div>
                            <h3>Conta Digital</h3>
                            <p>A solução completa de conta bancária para pessoa física e jurídica, transferências, pagamentos, PIX e muito mais no banco 274.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>04</span>
                            </div>
                            <h3>PIX</h3>
                            <p>A Tamino é participante direto do PIX e permite com que os usuários da conta digital façam e recebam transferências utilizando o PIX.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>05</span>
                            </div>
                            <h3>Cartão de Crédito</h3>
                            <p>Cartões de crédito com bandeira Mastercard, seu card design, aceito em todo o planeta! Contactless, panless, no name, cartão virtual, BIN exclusivo ou compartilhado e muito mais!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>06</span>
                            </div>
                            <h3>Boleto</h3>
                            <p>Boletos para depósito em conta ou de cobrança, boletos híbridos com QRCode PIX para facilitar o pagamento</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>07</span>
                            </div>
                            <h3>ON Boarding</h3>
                            <p>Checagem de identidade com ou sem biometria facial, para pessoas físicas e jurídicas!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>08</span>
                            </div>
                            <h3>Segurança</h3>
                            <p>Serviço de tecnologia e consultoria voltados a segurança do seu negócio de ponta à ponta</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>09</span>
                            </div>
                            <h3>Regulatório</h3>
                            <p>A Tamino se comunica diretamente com o Banco Central e todos os órgãos reguladores, enviando relatórios periódicos e em conformidade com mais de 3.000 normas.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>10</span>
                            </div>
                            <h3>TED</h3>
                            <p>A clássica transferência TED entre contas de mesmo banco ou bancos diferentes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->



    <app-funfact></app-funfact>
    <!-- End Reviews Area -->

</div>