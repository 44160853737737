<!-- Start Page Title Area -->
<div class="page-title-area item-bg-taminopay  jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Tamino Pay</h2>
            <p>A maquininha definitiva para o seu negório</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Currency Area -->
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image-pay"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Tamino Pay</h3>
                        <p>A única máquina de cartão que você precisa</p>
                        <p>Abra sua conta e solicite agora mesmo a sua maquiniha Tamino Pay e entre nessa revolução com a gente</p>
                        <ul class="list">
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Taxas Flexiveis, quanto mais voce fatura, menos você paga
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Conciliação automática e integração de Sistemas
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Pagamento em D+1, venda hoje, receba amanha, mesmo no parcelado
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Você pode comprar sua maquina ou comodatar com a gente
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <a href="https://app.taminobank.com.br/auth/registration" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->

    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Como Funciona?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Abra sua conta</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Abra sua conta gratuitamente, basta clicar no link, preencher os dados e enviar a sua documentação</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Solicite sua TaminoPay</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Solicite sua máquina e receba em seu endereço em poucos dias.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Comece a Faturar</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Use nossa maquina no dia a dia e pague cada vez menos taxas</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->



    <!-- Start Protec Area -->
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>No que vamos te ajudar?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Receba Pagamentos</h3>
                            <p>Receba suas vendas em cartões em 1 dia útil, mesmo suas vendas parceladas</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Escolha sua Taxa</h3>
                            <p>Taxas flexiveis de acordo com seu fluxo de caixa e sua escolha, você que manda aqui!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Gerenciamento de riscos</h3>
                            <p>Nossos sistemas de gerenciamento de riscos minimizam a inadimplencia, charge backs ou outros problemas que você possa ter</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Conciliação</h3>
                            <p>Fazemos a conciliação automatica pra você, do que você vendeu e recebeu e te avisamos em caso de problemas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->




    <app-talk-button></app-talk-button>

</div>