import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-two',
  templateUrl: './contact-two.component.html',
  styleUrls: ['./contact-two.component.scss']
})
export class ContactTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
