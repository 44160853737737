<!-- Start Feedback Area -->
<div class="feedback-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Não veja os nossos motivos, veja os deles</h2>
            <div class="bar"></div>
            <p>Leia o que alguns de nossos clientes dizem sobre nós e como ajudamos eles a crescer e descomplicar os serviços financeiros</p>
        </div>
        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <ngx-slick-carousel
                        class="carousel feedback"
                        #slickModal="slick-carousel"
                        [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/leo.jpg" alt="image">
                                </div>
                                <h3>Leonardo Cruz</h3>
                                <span>CEO</span>
                                <p>A tecnologia de cobrança da Tamino possibilitou o aumento da conversãoe a redução da inadimplencia de nossos clientes</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/lyjor.jpg" alt="image">
                                </div>
                                <h3>Lyjor Amorim</h3>
                                <span>CTO</span>
                                <p>As APIs da Tamino facilitaram muito a integração de nossos sistemas ao hub financeiro, reduzindo o trabalho de nosso contas a pagar</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/lilian.jpg" alt="image">
                                </div>
                                <h3>Lilian Moraes</h3>
                                <span>CFO</span>
                                <p>Usamos os serviços da Tamino para facilitar e muito o nosso financeiro, com a conciliação automatica da Tamino nosso time reduziu e muito o trabalho</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/alex.jpg" alt="image">
                                </div>
                                <h3>Alex Pohen</h3>
                                <span>CEO</span>
                                <p>O checkout da Tamino aumentou muito as conversões do nosso produto, vendemos muito mais e diminuimos os abandonos de carrinho.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/marco.jpg" alt="image">
                                </div>
                                <h3>Marco Maldonado</h3>
                                <span>CEO</span>
                                <p>A Tamino Pay facilitou e muito o dia a dia em nossa empresa, com a conciliação automatica ganhamos muito mais tempo para o que interessa, atender os nossos clientes</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/carlos.jpg" alt="image">
                                </div>
                                <h3>Carlos Muneratti</h3>
                                <span>CEO</span>
                                <p>Indicamos a Tamino para todos os nossos clientes, isso facilita muito o nosso trabalho de auditoria e governança corporativa.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/nicole.jpg" alt="image">
                                </div>
                                <h3>Nicole Lima</h3>
                                <span>Afiliada</span>
                                <p>O programa de afiliados da Tamino é o melhor do mercado, vendo tranquila porque sei que sempre vou receber corretamente minhas comissões</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/priscila.jpg" alt="image">
                                </div>
                                <h3>Priscila Arruda</h3>
                                <span>CFO</span>
                                <p>A emissão simplificada das cédulas de crédito que a Tamino oferece possibilitou a nossa empresa a aumentar o numero de clientes com rapidez e confiança</p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel
                        class="carousel thumbs"
                        #slickModal="slick-carousel"
                        [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/leo.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/lyjor.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/lilian.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/alex.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/marco.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/carlos.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/nicole.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/priscila.jpg" alt="client"></div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- End Feedback Area -->