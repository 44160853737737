<!-- Start Account Create Area -->
<div class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Abra sua conta grátis</h2>
            <p>Em poucos minutos comece a transformar sua vida financeira!</p>
            <a href="https://app.taminobank.com.br/auth/registration" class="btn btn-primary">Abra sua conta</a>
        </div>
    </div>
</div>
<!-- End Account Create Area -->