import { Component } from '@angular/core';

@Component({
  selector: 'app-otc',
  templateUrl: './otc.component.html',
  styleUrls: ['./otc.component.scss']
})
export class OtcComponent {

}
