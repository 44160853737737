<!-- Start Fun Facts Area -->
<div class="funfacts-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>A Tamino em Números</h2>
            <div class="bar"></div>
            <p>
                Tecnologia bancária modular que garante fluidez e segurança nas operações financeiras de credores e empresas.</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="180">00</span>mil</h3>
                    <p> PIX Recebidos por dia </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="20">00</span>bi</h3>
                    <p>Transacionados Mensalmente</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="5000">00</span>+</h3>
                    <p>Transações por Segundo</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="70">00</span>mil</h3>
                    <p>Contas Abertas</p>
                </div>
            </div>
        </div>
        <div class="contact-cta-box">
            <h3>Tem alguma pergunta?</h3>
            <p>Não hesite em entrar em contato</p>
            <a routerLink="/contact" class="btn btn-primary">Contate-nos</a>
        </div>
        <div class="map-bg">
            <img src="assets/img/map.png" alt="map">
        </div>
    </div>
</div>
<!-- End Fun Facts Area -->