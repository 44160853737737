<!-- Start Footer Area -->
<footer
    class="footer-area"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">  <img src="assets/img/logo1024.png" alt="logo" width="210px"> </a>
                        <p>Tamino, o seu Hub de soluções financeiras</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://wa.me/551151969904" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                        <li><a href="https://www.facebook.com/taminobank" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/taminobank" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/taminobank" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/tamino-bank/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>A Tamino</h3>

                    <ul class="list">
                        <li><a routerLink="/about-us">Sobre</a></li>
                        <li><a routerLink="/baas">BAAS</a></li>
                        <li><a routerLink="/busineess-account">Conta Empresarial</a></li>
                        <li><a routerLink="/personal-account">Conta Pessoal</a></li>
                        <li><a routerLink="/tamino-pay">TaminoPay</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Suporte</h3>

                    <ul class="list">
                        <li><i class="fab fa-whatsapp"></i>&nbsp; <a href="https://wa.me/551151969904">+55 (11) 5196-9904</a></li>
                        <li><a href="mailto:contato@taminobank.com.br">contato@taminobank.com.br</a></li>
                        <li><a href="mailto:contato@taminobank.com.br">ouvidoria@taminobank.com.br</a></li>
                        <li><a routerLink="/contact">Contato</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Endereços</h3>

                    <ul class="footer-contact-info">
                        <li><span>Endereço:</span> Av. Faria Lima, 1811, São Paulo</li>
                        <li><span>Email:</span> <a href="mailto:contato@taminobank.com.br">contato@taminobank.com.br</a></li>
                        <li><span>Telefone:</span> <a href="tel:08005916685">0800 591 6685</a></li>
                        <li><span>WhatsApp:</span> <a href="https://wa.me/551151969904">+55 (11) 5196-9904</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>© TAMINO TECNOLOGIA SERVICOS E PAGAMENTOS CNPJ 35.067.829/0001-96 -  Feito com &hearts; em São Paulo e na Florida</p>
            <p><small>A TAMINO TECNOLOGIA SERVICOS E PAGAMENTOS. inscrito no CNPJ/MF, sob n.º 35.067.829/0001-96 não é uma instituição financeira e não realiza operações de crédito diretamente. Atua como instituição de pagamento, regida pela Lei nº 12.865, de 9 de outubro de 2013 e regulada pela Circular do Banco Central do Brasil (“Bacen”) nº 3.885, de 26 de março de 2018 e como correspondente bancário nos termos do art. 2º, da Resolução CMN 3.954, de 24 de fevereiro de 2011 (“Resolução 4.656/2018). Alguns serviços fornecidos como, por exemplo, a transferência de recursos de contas de pagamento da Tamino para outros bancos, credenciamento, empréstimos e maquinas de cartão, são realizadas por meio de parcerias com instituições financeiras devidamente autorizadas pelo Bacen, Por meio da Cartos Sociedade de Crédito Direto S/A CNPJ/MF nº 21.332.862/0001-94, da ZOOP TECNOLOGIA E MEIOS DE PAGAMENTO S.A. CNPJ/MF nº 19.468.242/0001-32 e da PAYTIME FINTECH LTDA CNPJ/MF nº 40.073.909/0001-94 </small></p>
        </div>
    </div>
    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>


<div class="go-top"><i class="fas fa-arrow-up"></i></div>