import { Component } from '@angular/core';

@Component({
  selector: 'app-bussiness-account',
  templateUrl: './bussiness-account.component.html',
  styleUrls: ['./bussiness-account.component.scss']
})
export class BussinessAccountComponent {

}
