import { Component } from '@angular/core';

@Component({
  selector: 'app-tamino-pay',
  templateUrl: './tamino-pay.component.html',
  styleUrls: ['./tamino-pay.component.scss']
})
export class TaminoPayComponent {

}
