<!-- Start Error Area -->
<div class="error-area">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/404.png" alt="error">
            <h3>Página não encontada</h3>
            <p>A página que voce acessou não foi encontrada, por favor tente novamente</p>
            <a href="/" class="btn btn-primary">Voltar ao Inicio</a>
        </div>
    </div>
</div>
<!-- End Error Area -->