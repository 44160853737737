import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { AboutComponent } from './pages/about/about.component';
import { FeaturesComponent } from './pages/features/features.component';
import { TeamComponent } from './pages/team/team.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoFourComponent } from './demos/demo-four/demo-four.component';
import { DemoFiveComponent } from './demos/demo-five/demo-five.component';
import { DemoSixComponent } from './demos/demo-six/demo-six.component';
import { BlogRightSidebarComponent } from './pages/blog-right-sidebar/blog-right-sidebar.component';
import { DemoSevenComponent } from './demos/demo-seven/demo-seven.component';
import { BusinessComponent } from './pages/business/business.component';
import { PersonalComponent } from './pages/personal/personal.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { AboutTwoComponent } from './pages/about-two/about-two.component';
import { ContactTwoComponent } from './pages/contact-two/contact-two.component';
import {BaasComponent} from "./pages/baas/baas.component";
import {BussinessAccountComponent} from "./pages/bussiness-account/bussiness-account.component";
import {PersonalAccountComponent} from "./pages/personal-account/personal-account.component";
import {EscrowAccountComponent} from "./pages/escrow-account/escrow-account.component";
import {CheckoutComponent} from "./pages/checkout/checkout.component";
import {TaminoPayComponent} from "./pages/tamino-pay/tamino-pay.component";
import {OtcComponent} from "./pages/otc/otc.component";
import {BrokerComponent} from "./pages/broker/broker.component";
import {CardsComponent} from "./pages/cards/cards.component";
import {PayrollComponent} from "./pages/payroll/payroll.component";
import {HubComponent} from "./pages/hub/hub.component";
import {CashbackComponent} from "./pages/cashback/cashback.component";
import {MultiComponent} from "./pages/multi/multi.component";
import {CreditComponent} from "./pages/credit/credit.component";
import {TermsComponent} from "./pages/terms/terms.component";
import {PrivacyComponent} from "./pages/privacy/privacy.component";

const routes: Routes = [
    {path: '', component: DemoFiveComponent},

    {path: 'contact', component: ContactComponent},
    {path: 'about-us', component: AboutComponent},
    // Here add new pages component

    {path: 'baas', component: BaasComponent},
    {path: 'business-account', component: BussinessAccountComponent},
    {path: 'personal-account', component: PersonalAccountComponent},
    {path: 'escrow-account', component: EscrowAccountComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'tamino-pay', component: TaminoPayComponent},
    {path: 'otc', component: OtcComponent},
    {path: 'broker', component: BrokerComponent},
    {path: 'cards', component: CardsComponent},
    {path: 'payroll', component: PayrollComponent},
    {path: 'hub', component: HubComponent},
    {path: 'cashback', component: CashbackComponent},
    {path: 'multi', component: MultiComponent},
    {path: 'credit', component: CreditComponent},
    {path: 'terms', component: TermsComponent},
    {path: 'privacy', component: PrivacyComponent},


    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }