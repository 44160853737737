<!-- Start Page Title Area -->
<div class="page-title-area item-bg-broker jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Broker</h2>
            <p>Compre, venda e gerencie ativos digitais</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Currency Area -->
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image-broker"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Broker</h3>
                        <p>Compre, venda e gerencie ativos digitais</p>
                        <p>Proporcionamos aos nossos usuários uma estrutura segura, transparente e altamente tecnológica para facilitar operações financeiras digitas e gerar novas oportunidades de negócio.</p>
                        <ul class="list">
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Negociação Inteligente
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Diversificação
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Melhores Oportunidades
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image">
                                Independência
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <a href="https://app.taminobank.com.br/auth/registration" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->

    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Como Funciona?</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Cambio</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Com o Tamino Broker você pode realizar operações de câmbio desburocratizadas, como a compra e venda de moedas estrangeiras e criptomoedas.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Corretoras</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Trabalhamos com algumas das principais corretoras do mercado: Binance, FTX, Coinbase, Mercadobitcoin e Foxbit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Ativos</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Priorizamos negociações de capital estrangeiro e ativos digitais como: bitcoin, ethereum, NFTs e outros.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->






    <app-talk-button></app-talk-button>

</div>