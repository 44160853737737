<!-- Start Main Banner Area -->
<div class="home-area">
    <div class="container">
        <div class="home-slides owl-carousel owl-theme">
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Tamino Bank - Seu HUB de soluções financeiras</h1>
                            <p>Produtos e serviços financeiros que ajudam você nos seus objetivos, seja você pessoa física ou jurídica temos aqui as melhores soluções do mercado</p>
                            <a href="https://app.taminobank.com.br/auth/registration" target="_blank" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/taminoBank.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Serviços financeiros sob medida pra você</h1>
                            <p>Produtos e serviços financeiros que ajudam você nos seus objetivos.</p>
                            <a routerLink="/" class="btn btn-primary">Abra a sua conta</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/OnlineBanking.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Tamino Pay</h1>
                            <p>A melhor maquininha do mercado para o seu negócio, taxas flexiveis e muito mais! A única maquina do mercado com Tecnologia da Tamino!</p>
                            <a routerLink="/products/pay" class="btn btn-primary">Saiba mais</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/maquina-cartao.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Checkout Tamino</h1>
                            <p>Venda on-line para mais de 140 paises e receba na sua conta tamino</p>
                            <a routerLink="/products/checkout" class="btn btn-primary">Saiba mais</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/CheckoutOrder.png" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<div class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>
                        <h3>Controle Total</h3>
                        <p>Sua conta corrente com total controle e seguranças, conheça nossa solução</p>
                        <a routerLink="/features" class="read-more-btn">Saiba Mais</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>
                        <h3>Sistema 360</h3>
                        <p>Recebimentos, pagamentos, checkout, cobrança e muito mais na sua conta Tamino</p>
                        <a routerLink="/features" class="read-more-btn">Saiba Mais</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="fab fa-pix"></i>
                        </div>
                        <h3>PIX</h3>
                        <p>Use os serviços de PIX da Tamino para potencializar o seu negócio</p>
                        <a routerLink="/features" class="read-more-btn">Saiba Mais</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3>Segurança Primeiro</h3>
                        <p>Seus fundos seguros a todo momento, o regulatório é por nossa conta, integrado ao BACEN SPB 274.</p>
                        <a routerLink="/features" class="read-more-btn">Saiba Mais</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Featured Boxes Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Conta Corrente Tamino</h2>
                    <div class="bar"></div>
                    <p>Sua solução definitiva em serviços bancários, com a tecnologia e segurança da Tamino</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Envie e receba PIX</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pagamento de Boletos</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Impostos & Contas de Consumo</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Transferências (TED)</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Emissão de Boleto de Cobrança </span></li>
                        <li><span><i class="flaticon-check-mark"></i> QRCode Pix e Boleto Híbrido</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/cobranca.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/faturamento.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Da Pequena a Grande Empresa, e tambem para suas finanças pessoais</h2>
                    <div class="bar"></div>
                    <p>Sua conta pessoal ou empresarial na Tamino tem mais facilidades do que em qualquer outro lugar</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Conta Protegida</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cobranca Facilitada</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cartões </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Conta Escrow</span></li>
                        <li><span><i class="flaticon-check-mark"></i> HUB de Pagamentos</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cashback & Benefícios</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Soluções para o seu dia a dia</h2>
                    <div class="bar"></div>
                    <p>Com nossos serviços sua empresa vende mais, e tem diversas facilidades</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Links de Pagamento </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Checkout Personalizavel</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Programa de Afiliados</span></li>
                        <li><span><i class="flaticon-check-mark"></i> QRCODE PIX & Boleto Hibrido</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cripto moedas</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Conta Internacional </span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/tmn-btc.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Features Area -->
<div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Alguns de nossos serviços</h2>
            <div class="bar"></div>
            <p>Possuímos uma gama enorme de serviços para nossos clientes e parceiros abra sua conta e conte com nossa experiência</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="fab fa-pix"></i>
                    </div>
                    <h3>PIX</h3>
                    <p>Com o PIX Tamino você tem transferências ilimitadas e conta com toda a tecnologia necessária para proteger as suas transações </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <i class="fa fa-qrcode"></i>
                    </div>
                    <h3>Cobrança com QRCODE</h3>
                    <p>Emita cobranças com o PIX e controle os recebimentos do seu negócio de forma prática e rápida, com nossa conciliação automática.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="fa fa-barcode"></i>
                    </div>
                    <h3>Boleto Híbrido</h3>
                    <p>Seu cliente escolhe se paga o boleto pelo Código de Barras, Linha digitável ou QRCode PIX com Conciliação Automática</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-info"></i>
                    </div>
                    <h3>Informações dos clientes</h3>
                    <p>Efetue consultas em Bureu de Crédito antes de fechar qualquer negócio diretamente do seu painel de controle, saiba quem e o seu clientes antes de fechar um negócio</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-cursor"></i>
                    </div>
                    <h3>Checkkout & Afiliados</h3>
                    <p>Utilize nossas soluções de checkout para vender mais, controle o comissionamento de seus afiliados ou use o nosso programa de afiliados, venda mais</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-alarm"></i>
                    </div>
                    <h3>Lembretes de Cobrança</h3>
                    <p>Envie lembretes de cobrança automatizados aos seus clientes e aumente a conversão, diminua a inadimplência e fature mais</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Features Area -->

<!-- Start Invoicing Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Bank as a Service Tamino</h2>
                    <div class="bar"></div>
                    <p>Utilizando a solução White Label da Tamino, a sua empresa faz a abertura e o gerenciamento de contas dos seus clientes, e de quebra, rentabiliza em todas as transações e serviços financeiros movimentados por eles.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Um banco com a sua Marca</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Rentabilize com seus clientes</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Agregue mais valores e serviços</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Use nossa solução White Label</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Desenvolva sua solução com nossas APIs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Cuidamos de tudo pra voce.</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/api-tamino.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<app-funfact></app-funfact>
<!-- End Fun Facts Area -->

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>



<app-download></app-download>

<!-- Start Account Create Area -->
<app-account-button></app-account-button>
<!-- End Account Create Area -->