<!-- Start Page Title Area -->
<div class="page-title-area item-bg-contact jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Contato</h2>
            <p>Tem uma idéia? Uma Dúvida, vamos adorar ouvir você.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Fale com a Tamino</h2>
            <div class="bar"></div>
            <p>Envie sua mensagem, duvida, sugestão ou ideia ao nosso time de suporte e vamos adorar responder a você</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Endereço</span>
                            Av. Faria Lima, 1811, São Paulo, SP, Brasil
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:contato@taminobank.com.br">contato@taminobank.com.br</a>

                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Telefone</span>
                            <a href="tel:+551151969904">+55 11 5196-9904</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fab fa-whatsapp"></i>
                            </div>
                            <span>WhatsApp</span>
                            <a href="https://wa.me/551151969904">+55 11 5196-9904</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Nome">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Telefone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Assunto">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Sua Mensagem"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Enviar ></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</div>

<app-account-button></app-account-button>