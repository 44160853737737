<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Business</h2>
            <p>All the tools you need</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="currency-transfer-provider-with-background-color">

    <!-- Start Currency Area -->
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>Business Currency Transfers</h3>
                        <p>Donec sollicitudin molestie malesuada. proin eget tortor risus. nulla rutrum congue quis lorem ut libero malesuada feugiat donec rutrum congue leo eget malesuada.</p>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                        <ul class="list">
                            <li> 
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Receive Foreign Payments
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Budget Effectively
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Manage Currency Risk
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Highly Competitive Exchange Rates
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <a href="sign-up.html" class="btn btn-primary">Register Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Currency Area -->
    
    <!-- Start Money Transfer Area -->
    <div class="ctp-money-transfer-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>How it works</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Create a free account</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/customer.png" alt="image">
                            <div class="number">01</div>
                        </div>
                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. vivamus suscipit tortor eget felis porttitor volutpat. Sed porttitor lectus nibh.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Send your funds</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/profits.png" alt="image">
                            <div class="number">02</div>
                        </div>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-money-transfer-card">
                        <h3>Track your transfer</h3>
                        <div class="image">
                            <img src="assets/img/currency-transfer-provider/money-transfer/data.png" alt="image">
                            <div class="number">03</div>
                        </div>
                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. vivamus suscipit tortor eget felis porttitor volutpat.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Money Transfer Area -->

    <!-- Start Key Features Area -->
    <div class="ctp-key-features-area ptb-100">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Money Transfer Company Key Features</h2>
            </div>
            <div class="ctp-key-features-tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="security-tab" data-bs-toggle="tab" href="#security" role="tab" aria-controls="security">Security</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="cost-tab" data-bs-toggle="tab" href="#cost" role="tab" aria-controls="cost">Cost</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="speed-tab" data-bs-toggle="tab" href="#speed" role="tab" aria-controls="speed">Speed</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="accessibility-tab" data-bs-toggle="tab" href="#accessibility" role="tab" aria-controls="accessibility">Accessibility</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="security" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="fa-solid fa-check"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="fa-solid fa-check"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="fa-solid fa-check"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="fa-solid fa-check"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="cost" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="fa-solid fa-check"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="fa-solid fa-check"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="fa-solid fa-check"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="fa-solid fa-check"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="speed" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="fa-solid fa-check"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="fa-solid fa-check"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="fa-solid fa-check"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="fa-solid fa-check"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="accessibility" role="tabpanel">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="ctp-key-features-image"></div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="ctp-key-features-content">
                                    <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. pretium ut lacinia in, elementum id enim.</p>
                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi elementum id enim.</p>
                                    <ul class="list">
                                        <li><i class="fa-solid fa-check"></i> Quisque velit nisi, pretium ut lacinia in, elementum id enim.</li>
                                        <li><i class="fa-solid fa-check"></i> Vestibulum ac diam sit amet quam vehicula elementum sed sit amet.</li>
                                        <li><i class="fa-solid fa-check"></i> Donec rutrum congue leo eget malesuada.</li>
                                        <li><i class="fa-solid fa-check"></i> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <!-- End Key Features Area -->

    <!-- Start Protec Area -->
    <div class="ctp-protec-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>How We Can Help</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h3>Incoming payments</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>02</span>
                            </div>
                            <h3>Outgoing payments</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number color-two">
                                <span>03</span>
                            </div>
                            <h3>Risk management solutions</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="ctp-protec-card">
                        <div class="content">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h3>Online sellers</h3>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Protec Area -->

    <!-- Start Send Money Online Area -->
    <div class="ctp-send-money-online-area ptb-100 jarallax"  data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="ctp-send-money-online-content">
                        <h1>There are only a few steps you can take to send money online</h1>
                        <ul class="ctp-list">
                            <li> 
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Move money on-the-go
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Currency wallets
                            </li>
                            <li>
                                <img src="assets/img/currency-transfer-provider/circle-check.svg" alt="image"> 
                                Set rate alerts
                            </li>
                        </ul>
                        <div class="video-view">
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="fas fa-play"></i> 
                                <span>See how it works</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <form class="ctp-send-money-online-form">
                        <div class="form-header">
                            <span>Exchange Rate</span>
                            <h3>1 GBP = 1.29356 USD</h3>
                        </div>
                        <div class="form-content">
                            <div class="form-group">
                                <label>Delivery Method</label>
                                <select>
                                    <option>Bank Transfer</option>
                                    <option>Send Money</option>
                                    <option>Interest Money</option>
                                    <option>Invest Money</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>You Transfer</label>
                                <input type="text" class="form-control" autocomplete="off" value="100">
                                <div class="amount-currency-select">
                                    <select>
                                        <option>GBP</option>
                                        <option>USD</option>
                                        <option>EUR</option>
                                        <option>BRL</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group zero">
                                <label>Recipient Gets</label>
                                <input type="text" class="form-control" autocomplete="off" value="129.35">
                                <div class="amount-currency-select">
                                    <select>
                                        <option>USD</option>
                                        <option>GBP</option>
                                        <option>EUR</option>
                                        <option>BRL</option>
                                    </select>
                                </div>
                            </div>
                            <div class="info">
                                <p><span>Delivery Time:</span> 1 Working Days</p>
                            </div>
                            <button type="submit" class="btn btn-primary">Send Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- End Send Money Online Area -->

    <!-- Start FAQ Area -->
    <div class="ctp-faq-area ptb-100">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Frequently Asked Questions</h2>
            </div>
            <div class="ctp-faq-accordion">
                <div class="accordion" id="FaqAccordion">
                    <div class="accordion-item">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How do I create an account with Klob?
                        </button>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            How to send money online?
                        </button>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Is my money safe with Klob?
                        </button>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            What verification do I need to send money? 
                        </button>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How does Klob protect your money?
                        </button>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                            <div class="accordion-body">
                                <p>Quisque velit nisi, pretium ut lacinia in, elementum id enim. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. pellentesque in ipsum id orci porta dapibus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. quisque velit nisi, pretium ut lacinia in, elementum id enim.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End FAQ Area -->

</div>