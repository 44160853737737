import { Component } from '@angular/core';

@Component({
  selector: 'app-escrow-account',
  templateUrl: './escrow-account.component.html',
  styleUrls: ['./escrow-account.component.scss']
})
export class EscrowAccountComponent {

}
